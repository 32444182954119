.productpage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Stack product section and related products vertically */
  height: auto; /* Adjust height to auto for flexibility */
  width: 100%;
}

.productpage-product {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 70%;
  padding: 20px;
  gap: 1em;
  border: 1px solid purple;
  border-radius: 20px;
  background-color: #f9f9f9;
  z-index: 0;
}

.productpage-product > img {
  width: 400px;
  height: 300px;
  object-fit: contain;
  border-radius: 10px;
}

.productpage-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
  gap: 10px;
}

#description {
  max-width: 100%;
}

#price {
  color: red;
  font-weight: bold;
}

.productpage-side button {
  background-color: purple;
  color: white;
  border-radius: 20px;
  width: 100px;
  height: 40px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.productpage-side button:hover {
  background-color: white;
  color: purple;
  border: 1px solid purple;
}

.productpage-image-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
#productpage-image{
  height: 300px;
  width: auto;
}

.related-products{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}


/* Media Queries */
@media (max-width: 768px) {
  .productpage-product {
    flex-direction: column;
    width: 90%;
  }

  #productpage-image{
    height: 250px;
    width: 300px;
  }

  .productpage-side {
    align-items: center;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .productpage-product {
    width: 100%;
    padding: 10px;
  }

  .productpage-image-center> #productpage-image{
    height: 210px;
    width: 270px;
  }
}
