.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
}

.promo-code {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: purple;
  color: white;
  height: 30px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 4;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2em;
  top: 0;
  height: 15vh;
  width: 100%;
  background-color: purple;
  margin-bottom: 1em;
  z-index: 3;
}

.logo {
  height: 100px;
  width: 200px;
  margin-right: 45px;
  cursor: pointer;
  /* background-color: white; */
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
  background-image: url("./images/logo.png");
}

.nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  user-select: none;
}

.logo:active {
  transform: scale(0.98);
}

.nav-button:hover::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: white;
}

.var-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 85%;
}

/* Medya Sorguları */

@media (max-width: 992px) {
  nav {
    flex-direction: row;
    height: auto;
    padding: 10px 0;
  }

  .logo {
    height: 80px;
    width: 160px;
    margin-right: 0;
  }

  .nav-button {
    font-size: 14px;
  }

  .promo-code {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  nav {
    gap: 1em;
    flex-direction: row;
  }

  .logo {
    height: 70px;
    width: 140px;
  }

  .nav-button {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  nav {
    flex-direction: row;
    padding: 5px 0;
  }

  .logo {
    height: 60px;
    width: 120px;
  }

  .nav-button {
    font-size: 10px;
    gap: 10px;
  }

  .promo-code {
    font-size: 12px;
  }
}
