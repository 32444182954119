
.partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
}

#brander-photo {
  width: 70%;
  height: 550px;
}

.partner button {
  background-color: purple;
  color: white;
  width: 250px;
  height: 50px;
  border-radius: 40px;
  font-size: 22px;
  border: none;
  cursor:  pointer;
  transition: background-color 0.3s ease;
}

.partner button:hover {
  background-color: white;
  color: purple;
  border: 1px solid purple;
}

.partner h1 {
  font-size: 36px;
}

.partner h2 {
  font-size: 32px;
}

.partner-bottom p, .steps p, #p1 {
  text-align: left;
  font-size: 26px;
}

#btn1 {
  margin-top: 1em;
}

#p1 {
  max-width: 60%;
}

.steps {
  max-width: 70%;
  margin-bottom: 1em;
}

.step {
  display: flex;
  flex-direction: row;
  gap: 2em;
}

.partner-step {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.step img {
  width: 50%;
  height: 500px;
  object-fit: cover;
}

.partner-bottom {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}

.partner a {
  font-size: 22px;
}

footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 100%;
  max-width: 80%;
}

footer > img {
  max-width: 40%;
}

#p {
  max-width: 40%;
}

.footer-side {
  display: flex;
  flex-direction: column;
  max-width: 20%;
}

.signup-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Medya Sorguları */

@media (max-width: 1200px) {
  #brander-photo {
    width: 80%;
    height: 450px;
  }

  .partner button {
    width: 220px;
    height: 45px;
    font-size: 20px;
  }

  .partner h1 {
    font-size: 32px;
  }

  .partner h2 {
    font-size: 28px;
  }

  .partner-bottom p, .steps p, #p1 {
    font-size: 24px;
  }

  #p1 {
    max-width: 65%;
  }

  .steps {
    max-width: 75%;
  }

  .step img {
    width: 55%;
    height: 350px;
  }

  .partner-step {
    max-width: 60%;
  }

  .partner-bottom {
    max-width: 75%;
  }

  footer > img {
    max-width: 45%;
  }
}

@media (max-width: 992px) {
  #brander-photo {
    width: 90%;
    height: 350px;
  }

  .partner button {
    width: 200px;
    height: 40px;
    font-size: 18px;
  }

  .partner h1 {
    font-size: 28px;
  }

  .partner h2 {
    font-size: 24px;
  }

  .partner-bottom p, .steps p, #p1 {
    font-size: 22px;
  }

  #p1 {
    max-width: 70%;
  }

  .steps {
    max-width: 80%;
  }

  .step {
    flex-direction: column;
    gap: 1em;
  }

  .step img {
    width: 100%;
    height: auto;
  }

  .partner-step {
    max-width: 70%;
  }

  .partner-bottom {
    max-width: 80%;
  }

  footer > img {
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  #brander-photo {
    width: 100%;
    height: 300px;
  }

  .partner button {
    width: 180px;
    height: 35px;
    font-size: 16px;
  }

  .partner h1 {
    font-size: 24px;
  }

  .partner h2 {
    font-size: 20px;
  }

  .partner-bottom p, .steps p, #p1 {
    font-size: 20px;
  }

  #p1 {
    max-width: 80%;
  }

  .steps {
    max-width: 90%;
  }

  .partner-step {
    max-width: 80%;
  }

  footer {
    flex-direction: column;
    height: auto;
  }

  footer > img {
    max-width: 60%;
  }
}

@media (max-width: 576px) {
  #brander-photo {
    height: 250px;
  }

  .partner button {
    width: 150px;
    height: 30px;
    font-size: 14px;
  }

  .partner h1 {
    font-size: 20px;
  }

  .partner h2 {
    font-size: 18px;
  }

  .partner-bottom p, .steps p, #p1 {
    font-size: 18px;
  }

  #p1 {
    max-width: 90%;
  }

  .partner-step {
    max-width: 90%;
  }

  footer > img {
    max-width: 70%;
  }
}