/* Center the entire page */
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

/* Blog detail section */
.blog-detail {
  max-width: 800px;
  width: 100%;
  text-align: center;
}

.blog-detail h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.blog-image {
  max-width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

.blog-detail p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;
}

/* Recommended products section */
.recommended-products {
  max-width: 800px;
  width: 100%;
  text-align: center;
}

.recommended-products h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.product-list-1 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-item-1 {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.product-item-1:hover {
  transform: translateY(-5px);
}

.product-image-1 {
  max-width: 150px;
  height: 150px;
  border-radius: 4px;
}

.product-item-1 p {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
}
/* .product-image-1{
  width: 100px;
  height: auto; 
} */


/* Tags section */
.tags {
  margin-top: 30px;
}

.tags h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.tag {
  display: inline-block;
  background-color: #f1f1f1;
  padding: 5px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.tag:hover {
  background-color: #e0e0e0;
}
