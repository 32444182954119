.product-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px; 
    padding: 20px;
  }
  
  .product-card {
    border: 1px solid #e1e1e1;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
  }
  
  .product-image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
  .product-card >p {
    margin-bottom: 10px;/* Paragraflar için boşluk bırakır */
    margin-top: auto;
    max-height: 155px;
    overflow: hidden;
  }
  
  .price {
    margin: 10px 0;
  }
  
  .new-price {
    color: #cf2127;
    font-weight: bold;
    margin-right: 10px;
  }
   .buy-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #5c2d91;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .buy-button:hover {
    background-color: #421f66;
  }
  
  @media (max-width: 1024px) {
    .product-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .product-list {
      grid-template-columns: repeat(1, 1fr);
    }
  }