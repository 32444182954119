.shipping-policy {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  color: #555;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  color: #666;
  line-height: 1.6;
}

table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

table th,
table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

table th {
  background-color: #f2f2f2;
}

table td {
  background-color: #fff;
}
.shipping-policy > button {
  cursor: pointer;
}
/* Responsive Styling */
@media (max-width: 768px) {
  .shipping-policy {
    margin: 10px;
    padding: 15px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }

  table th,
  table td {
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .shipping-policy {
    margin: 5px;
    padding: 10px;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 16px;
  }

  p {
    font-size: 12px;
  }

  table th,
  table td {
    padding: 6px;
    font-size: 12px;
  }

  /* Table responsive for smaller screens */
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
