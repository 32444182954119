.related-products-carousel {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 71.5%;
  margin-top: 2rem;
  margin-bottom: 30px;
}

.related-products-carousel .slick-slide {
  padding: 0 10px;
}

.related-product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 150px;
  height: 280px;
  border: 1px solid purple;
  border-radius: 20px;
}

.related-products-carousel h3 {
  text-align: center;
  color: purple;
  margin-bottom: 1.5rem;
}

.related-products-carousel h4 {
  text-align: center;
  height: 50px;
}

.related-product-card img {
  width: 60%;
  height: 100px;
  object-fit: contain;
  margin: 5px auto;
  cursor: pointer;
}

.related-products-carousel .related-product-card button {
  background-color: purple;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.related-products-carousel .related-product-card button:hover {
  background-color: white;
  color: purple;
  border: 1px solid purple;
}

/* Tablet ekranlar için */
@media (max-width: 1024px) {
  .related-product-card {
    width: 130px;
    height: 250px;
  }
  .related-product-card img {
    height: 90px;
  }
  .related-products-carousel {
    width: 96%;
  }
}

/* Mobil ekranlar için */
@media (max-width: 768px) {
  .related-product-card {
    width: 100px;
    height: 260px;
  }
  .related-product-card img {
    height: 80px;
    width: 100px;
  }
}
