.shipping-policy {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  color: #555;
  margin-top: 20px;
  margin-bottom: 10px;
}

p,
ul {
  color: #666;
  line-height: 1.6;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

.shipping-policy > button {
  cursor: pointer;
  margin-top: 10px;
}
/* Responsive Styling */
@media (max-width: 768px) {
  .shipping-policy {
    margin: 10px;
    padding: 15px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  p,
  ul {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .shipping-policy {
    margin: 5px;
    padding: 10px;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 16px;
  }

  p,
  ul {
    font-size: 12px;
  }
}
