.search-bar {
    display: flex;
    justify-content: center;
    margin: 20px 0; /* Üst ve alt boşluk */
  }
  
  .search-bar input {
    width: 100%;
    max-width: 600px; /* Büyük cihazlar için maksimum genişlik */
    padding: 12px 20px;
    border: 2px solid #ccc;
    border-radius: 30px;
    font-size: 18px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    transition: border 0.3s ease, box-shadow 0.3s ease;
  }
  
  .search-bar input:focus {
    outline: none;
    border: 2px solid purple; /* Odaklanıldığında sınır rengini değiştir */
    box-shadow: 0px 4px 12px rgba(98, 0, 234, 0.4);
  }
  
  /* Tablet ve daha küçük cihazlar için */
  @media (max-width: 1024px) {
    .search-bar input {
      max-width: 500px;
      font-size: 16px;
    }
  }
  
  /* Küçük tablet ve büyük telefonlar için */
  @media (max-width: 768px) {
    .search-bar input {
      max-width: 400px;
      font-size: 15px;
    }
  }
  
  /* Telefonlar için */
  @media (max-width: 480px) {
    .search-bar input {
      max-width: 300px;
      font-size: 14px;
      padding: 10px 15px;
    }
  }
  
  /* Çok küçük telefonlar için */
  @media (max-width: 360px) {
    .search-bar input {
      max-width: 250px;
      font-size: 13px;
      padding: 8px 12px;
    }
  }
  