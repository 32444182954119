.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 300px;
}
.product > a > button {
  background-color: purple;
  color: white;
  border-radius: 20px;
  width: 90px;
  height: 30px;
  border: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.product > a > button:hover {
  background-color: white;
  color: purple;
  border: 1px solid purple;
}

.product > img {
  max-height: 50%;
  max-width: 95%;
}
