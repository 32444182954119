.blog-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 0 auto;
}

.card-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: row;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 60%;
  gap: 20px;
}

.card > img {
  height: 150px;
  width: 30%;
  border-radius: 6px;
}

.card > img:hover {
  animation: buyut 1s forwards;
}

@keyframes buyut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.card-title {
  margin: 0 0 10px;
}

.card-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
}

.card-link {
  color: #007bff;
  max-width: 90px;
  text-decoration: none;
  cursor: pointer;
}

.card-link:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .blog-list {
    width: 90%;
  }

  .card {
    width: 80%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .card > img {
    width: 80%;
    height: auto;
  }

  .card-right {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .card {
    width: 100%;
    padding: 15px;
  }

  .card > img {
    width: 80%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .card {
    padding: 10px;
  }

  .card > img {
    width: 80%;
  }

  .card-right {
    width: 100%;
  }
}
