* {
  box-sizing: border-box;
}

.defaultPage html,
body {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  width: 100%;
}

/* Ana Sayfa */
.defaultPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Carousel */
.carousel {
  width: 100%;
  max-width: 1200px;
  margin: 20px 0;
}

.carousel-slide {
  position: relative;
}

.carousel-image {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
}

.carousel-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  height: 50px;
  width: 100px;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  transition: background-color 0.3s ease;
}

.carousel-button:hover {
  background-color: black;
  color: white;
  border: 1px solid white;
}

/* Ürün Bölümü */
.product-section {
  text-align: center;
  margin: 40px 0;
}

.product-section > h1 {
  color: purple;
}

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.wide-ads {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  width: 100%;
  max-width: 1200px;
}

.wide-ads img {
  max-width: 50%;
  object-fit: cover;
}

.wide-ads-side {
  max-width: 45%;
}

.wide-ads-side p {
  max-width: 90%;
}

.wide-ads-side button {
  margin-top: 20px;
  height: 40px;
  width: 100px;
  border-radius: 30px;
  border: 1px solid black;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.wide-ads-side button:hover {
  background-color: white;
  color: black;
}

.blogs{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}
.blog-button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 60px;
  background-color: purple;
  color: white;
  border: 1px solid purple;
  border-radius: 20px;
  cursor: pointer;
}
.blog-button strong{
  font-size: 18px;
}
.blog-button:hover{
  background-color: white;
  color: purple;
  transition: background-color 0.3s ease;
}

footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 250px;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
  padding: 0 20px;
  gap: 1em;
  margin-bottom: 10px;
}

footer > img {
  max-width: 40%;
}

#p {
  max-width: 100%;
  text-align: left;
}
.footer-mid-bottom{
  display: flex;
  gap: 10px;
}
.footer-mid>.footer-mid-bottom>a{
  font-size: 14px;
  max-width: 100px;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
.footer-mid{
  display: flex;
  flex-direction: column;
  width: 40%;
  max-height: 150px;
}
.footer-side {
  display: flex;
  flex-direction: column;
  max-width: 20%;
}

/* Media Queries */
@media (max-width: 1200px) {
  .products {
    grid-template-columns: repeat(3, 1fr);
  }

  .wide-ads img {
    max-width: 60%;
  }
  .wide-ads-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wide-ads-side {
    max-width: 50%;
  }

  footer > img {
    max-width: 50%;
  }
}

@media (max-width: 992px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }

  .wide-ads {
    flex-direction: column;
    align-items: center;
  }
  .wide-ads-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wide-ads img {
    max-width: 90%;
  }

  .wide-ads-side {
    max-width: 100%;
    text-align: center;
  }

  .wide-ads-side > h1 {
    max-width: 90%;
  }
  footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: auto;
    padding: 20px 10px; /* Yatay ve dikeyde daha fazla boşluk ekleyin */
  }

  .footer-mid {
    width: 90%; /* Footer ortasındaki metni tam genişlik yapın */
    max-height: none; /* Max-height sınırını kaldırın */
    margin-bottom: 20px; /* Daha fazla alt boşluk ekleyin */
  }

  .footer-mid p {
    text-align: center; /* Mobil cihazlar için ortalayın */
    font-size: 14px; /* Yazı boyutunu küçültün */
  }

  .footer-mid > a {
    font-size: 16px; /* Linklerin okunabilirliğini artırın */
    margin-top: 10px; /* Linkler arasında boşluk bırakın */
  }

  .footer-side {
    width: 90%; /* Yan içeriği de tam genişlik yapın */
    margin-top: 20px; /* Diğer bölümlerle arada boşluk bırakın */
  }

  .footer-side p {
    font-size: 14px; /* Yazı boyutunu biraz küçültün */
  }

  footer > img {
    max-width: 60%; /* Logoyu küçültün ama merkezde tutun */
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .carousel-button {
    height: 40px;
    width: 80px;
  }

  .products {
    grid-template-columns: repeat(1, 1fr);
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: auto;
    padding: 0 10px;
  }
  .wide-ads-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wide-ads > img {
    max-width: 90%;
  }

  .wide-ads-side > h1 {
    max-width: 90%;
  }

  footer > img {
    max-width: 60%;
    margin-bottom: 20px;
  }

  #p {
    max-width: 80%;
    margin-bottom: 20px;
  }

  .footer-side {
    max-width: 80%;
  }
}

@media (max-width: 576px) {
  .carousel-button {
    height: 35px;
    width: 70px;
  }

  .product-section > h1 {
    font-size: 1.2em;
  }

  .wide-ads > img {
    max-width: 90%;
  }

  .wide-ads-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wide-ads-side > h1 {
    max-width: 90%;
  }

  .wide-ads-side button {
    height: 35px;
    width: 80px;
    margin-bottom: 10px;
  }
  
  #p {
    max-width: 80%;
  }

  footer {
    padding: 10px; /* Daha küçük padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  footer img {
    max-width: 80%; /* Logo daha da küçültülebilir */
    margin-bottom: 15px;
  }

  .footer-mid {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%; /* Tam genişlik */
    margin-bottom: 15px;
  }

  .footer-mid p {
    font-size: 13px; /* Daha küçük metin boyutu */
  }

  .footer-side {
    width: 100%; /* Tam genişlik */
    margin-top: 15px;
  }

  .footer-side p {
    font-size: 13px; /* Daha küçük metin */
  }
}
